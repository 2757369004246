import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const featuresdiagram = require("../../images/featuresdiagram.png")
const overviewdiagram = require("../../images/overviewdiagram.png")
const webhosting = require("../../images/webhosting.png")
const workflow = require("../../images/workflow.svg")
const webdesign = require("../../images/webdesign.svg")
const communication = require("../../images/communication.svg")
const distributed = require("../../images/distributed.svg")
const language = require("../../images/language.svg")
const orchestration = require("../../images/orchestration.svg")
const hybridprogramming = require("../../images/hybridprogramming.png")
const hardwaredata = require("../../images/hardwaredata.png")
const edgeneural = require("../../images/edgeneural.png")

const IndexPage = () => {
  const [hover, setHover] = useState({
    0: false,
    1: false,
    2: false,
  })
  return (
    <Layout>
      <SEO
        title="PCS: Productive Computational Science platform"
        description=""
        lang="en"
        meta={[]}
      />

      {/* Hero */}
      <div className="banner-image pcs">
        <div className="hero-text">
          <h1>Productive Computational Science platform</h1>
          <h3>
            PCS unifies multiple programming models into a
            <b> single programming environment</b> to facilitate large-scale,
            accelerator-aware, heterogeneous computing for next-generation
            scientific applications.
          </h3>
          <div className="btn-div">
            <Button variant="light" to="/pcs/why-pcs" as={Link}>
              Learn More
            </Button>
            <Button variant="light" to="/pcs/join-pcs" as={Link}>
              Collaborate
            </Button>
          </div>
        </div>
      </div>

      {/* Projects Section */}
      <section className="projects bg-darkened">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Projects</h2>
            {/* Cards */}
            <div
              className={`col-lg project-cards ${hover[0] ? "hovered" : ""}`}
            >
              <Link
                to="/pcs/projects/hardware-data-structures"
                onMouseEnter={() => setHover({ ...hover, 0: true })}
                onMouseLeave={() => setHover({ ...hover, 0: false })}
              >
                <div className="category-label">
                  <span className="hardware">Hardware Stacks</span>
                </div>
                <h3>Hardware Data Structures</h3>

                <div className="img-container">
                  <img src={hardwaredata} alt="" className="img-fluid" />
                </div>
              </Link>
              <h5>
                Implementation and protocol for efficient
                host/accelerator-to-accelerator intercommunication.
              </h5>

              <h4 className="text-center">Sub-projects</h4>

              <Button
                className="mb-2 projects-btn"
                as={Link}
                to="/pcs/projects/hardware-data-structures/#fshmem-gasnet-fpga"
              >
                FSHMEM: GasNet-FPGA
              </Button>
              <Button
                className="mb-2 projects-btn"
                as={Link}
                to="/pcs/projects/hardware-data-structures/#apache-arrow"
              >
                Apache Arrow*
              </Button>
            </div>

            <div
              className={`col-lg project-cards ${hover[1] ? "hovered" : ""}`}
            >
              <Link
                to="/pcs/projects/hybrid-programming-model"
                onMouseEnter={() => setHover({ ...hover, 1: true })}
                onMouseLeave={() => setHover({ ...hover, 1: false })}
              >
                <div className="category-label">
                  <span className="hybrid">System Software</span>
                </div>
                <h3>Hybrid Programming Model</h3>

                <div className="img-container">
                  <img src={hybridprogramming} alt="" className="img-fluid" />
                </div>
              </Link>
              <h5>
                Performance prediction model and software abstractions for
                accelerator-HPC tools integration.
              </h5>

              <h4 className="text-center">Sub-projects</h4>
              <Button
                className="mb-2 projects-btn"
                as={Link}
                to="/pcs/projects/hybrid-programming-model/#pgas-open-shmem"
              >
                PGAS/OpenSHMEM
              </Button>
              <Button
                className="mb-2 projects-btn"
                as={Link}
                to="/pcs/projects/hybrid-programming-model/#hybrid-spmd-mpsd"
              >
                Hybrid SPMD-MPSD
              </Button>
            </div>
            <div
              className={`col-lg project-cards ${hover[2] ? "hovered" : ""}`}
            >
              <Link
                to="/pcs/projects/edge-neural-network"
                onMouseEnter={() => setHover({ ...hover, 2: true })}
                onMouseLeave={() => setHover({ ...hover, 2: false })}
              >
                <div className="category-label">
                  <span className="edge">Data Engineering</span>
                </div>
                <h3>Edge Neural Network</h3>

                <div className="img-container">
                  <img src={edgeneural} alt="" className="img-fluid" />
                </div>
              </Link>
              <h5>
                Graph partitioning, optimization, and dataflow schemes for large
                DNNs.
              </h5>

              <h4 className="text-center">Sub-projects</h4>
              <Button
                className="mb-2 projects-btn"
                as={Link}
                to="/pcs/projects/edge-neural-network/#cnn-rnn"
              >
                CNN AND RNN
              </Button>
              <Button
                className="mb-2 projects-btn"
                as={Link}
                to="/pcs/projects/edge-neural-network/#recommender"
              >
                Recommender
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Principles Section */}
      <section className="principles-section">
        <div className="container">
          <h2 className="text-center mb-5">Principles</h2>
          <div className="row">
            <img
              src={overviewdiagram}
              alt=""
              className="img-fluid head-block"
            />
            <div className="col-md-4 principle-block">
              <h3>Workflow-oriented</h3>
              <p>
                Easily define your application as a workflow then integrate your
                data all in a user-friendly programming environment.{" "}
              </p>
              <img alt="" height={214} src={workflow} />
            </div>
            <div className="col-md-4 principle-block">
              <h3>Automated</h3>
              <p>
                Workflows are composed of either statically or dynamically
                generated tasks. PCS maps these tasks for efficient execution.
              </p>
              <img alt="" height={214} src={webdesign} />
            </div>
            <div className="col-md-4 principle-block">
              <h3>Heterogeneous</h3>
              <p>
                Program execution occurs as sets of hardware microservices,
                leveraging high-performance compute backends like FPGA, CPU and
                ASIC.
              </p>
              <img alt="" height={214} src={webhosting} />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-darkened features-section">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Features</h2>
            {/* First Column */}
            <div className="col-lg-6 d-flex flex-column justify-content-around">
              <div className="feature-block mb-5">
                <div className="img-div">
                  <img
                    height={75}
                    width={75}
                    className="img-placeholder"
                    src={language}
                  />
                </div>
                <div className="text-div">
                  <h3>Domain-specific language (DSL)</h3>
                  <p>
                    Source-to-source translation and remote memory access (RMA)
                    abstractions incorporated with familiar frameworks like
                    TensorFlow and Airflow.
                  </p>
                </div>
              </div>
              <div className="feature-block mb-5">
                <div className="img-div">
                  <img
                    height={75}
                    width={75}
                    className="img-placeholder"
                    src={orchestration}
                  />
                </div>
                <div className="text-div">
                  <h3>Scalable accelerator-aware orchestration</h3>
                  <p>
                    Data/task parallel processing under a single, unified view
                    of compute, communication, and memory resources.
                  </p>
                </div>
              </div>
              <div className="feature-block mb-5">
                <div className="img-div">
                  <img
                    height={75}
                    width={75}
                    className="img-placeholder"
                    src={distributed}
                  />
                </div>
                <div className="text-div">
                  <h3>Throughput-aware distributed runtime system</h3>
                  <p>
                    Load-balancing of intra-node against inter-node
                    communication while accommodating for compute-pool
                    heterogeneity and bulk synchronizations.
                  </p>
                </div>
              </div>
              <div className="feature-block mb-5">
                <div className="img-div">
                  <img
                    height={75}
                    width={75}
                    className="img-placeholder"
                    src={communication}
                  />
                </div>
                <div className="text-div">
                  <h3>
                    Latency-aware accelerator-to-accelerator intercommunication
                  </h3>
                  <p>
                    Exploitation of one-sided communication and reduced
                    intercommunication overhead through GasNet and PGAS
                    hardware-based libraries.
                  </p>
                </div>
              </div>
            </div>
            {/* Second Column ( Image ) */}
            <div className="col-lg-6 image-block">
              <img
                src={featuresdiagram}
                alt="Feature Overview"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Collaborators Section Section */}
      <section>
        <div className="container">
          <div className="row collab-text">
            <h2 className="text-center mb-3">
              Organizations using and contributing to PCS
            </h2>

            <p className="text-center">Flapmax</p>
            <p className="text-center">University of Florida</p>
            <p className="text-center">Oak Ridge National Lab</p>
            <p className="text-center">Brookhaven National Lab</p>
            <p className="text-center">Lawrence Livermore National Lab</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
